import { makeAutoObservable } from 'mobx'

import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGE } from 'constants/pagination.constants'
import { apiClient } from 'utils/requests.utils'
import { SellableItem as Item, SellablesAPI, createSellablesApi, defaultSellableItem } from '../api/sellables.api'
import { handleResetStore } from './actions/reset-data'
import { handleFetchItem, WithFetchItem } from './actions/fetch-item'
import { handleFetchFullList, handleFetchList, WithFetchList } from './actions/fetch-list'
import { handleApplyFilters, WithFilters } from './actions/filters'
import { handlePaginationChange } from './actions/pagination-change'

export class SellablesStore implements WithFetchItem<Item>, WithFetchList<Item>, WithFilters<Item> {
  declare api: SellablesAPI
  list: Item[] = []
  item: Item = defaultSellableItem
  isLoading = false
  customLoadingEnabled = false
  customLoading = false
  pagination = {
    per: DEFAULT_ITEMS_PER_PAGE,
    page: DEFAULT_PAGE,
    total: 0,
  }

  expands = []
  scopes = new Map()
  filters = new Map()

  constructor() {
    this.api = createSellablesApi(apiClient)
    makeAutoObservable(this)
  }

  setLoading(value) {
    if (this.customLoadingEnabled) {
      this.isLoading = false
      this.customLoading = value
    } else {
      this.isLoading = value
    }
  }

  toggleCustomLoading = (value?: boolean) => {
    this.customLoadingEnabled = value || !this.customLoadingEnabled
  }

  setItem(item: Item) {
    this.item = item
  }

  resetItem() {
    this.item = defaultSellableItem
  }

  setExpands = (expand) => {
    this.expands = [...this.expands, ...expand]
  }

  resetExpands = () => (this.expands = [])

  setList(list: Item[]) {
    this.list = list
  }

  setPagination(pagination) {
    this.pagination = {
      ...this.pagination,
      ...pagination,
    }
  }

  setFilter(key, value) {
    this.filters.set(key, value)
  }

  setScope(key, value) {
    this.scopes.set(key, value)
  }

  resetScopes() {
    this.scopes = new Map()
  }

  resetFilters() {
    this.filters = new Map()
  }

  fetchItem = async (id, data?) => await handleFetchItem<Item>(this, id, data)

  fetchList = async (data?) => await handleFetchList<Item>(this, data)

  fetchFullList = async (data) => await handleFetchFullList(this, data)

  applyFilters = async () => await handleApplyFilters(this)

  applyPagination = async (key, value) => await handlePaginationChange<Item>(this, key, value)

  resetData = () => handleResetStore(this)

  // set item for course/membership access
  setMembershipSellable = (item) => {
    this.item = item
  }

  // fetching sellable without initialize session
  fetchMembershipSellable = async (id) => {
    this.setLoading(true)

    const resp = await this.api.fetchMembershipSellable(id)
    const { success, data } = resp || {}
    // @ts-ignore
    const { list = [] } = data || {}

    if (success) {
      this.setMembershipSellable(list[0] || {})
    }

    this.setLoading(false)
  }
}

export default new SellablesStore()
